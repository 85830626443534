<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="67">
          <v-toolbar-title>
            {{ prData.PRCODE }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          Grand Total: ₱ {{ formatPrice(prData.GrandTotal)  }}
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text style="max-height: 900px">
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="ppmpItemID"
                class="elevation-1 mt-4"
                disable-pagination
                hide-default-footer
                show-select
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-autocomplete
                    class="ml-4"
                    color="green"
                    v-model="SpecificFundSourceID"
                    item-text="SpecificFundSourceDescription"
                    item-value="SpecificFundSourceID"
                    :items="specificFundSource"
                    v-if="prData.isParent == 1"
                    label="Specific Fund Source"
                  >
                  </v-autocomplete>
                    <v-btn
                      color="green darken-2"
                      outlined
                      class="mb-2 ml-4 mt-2 mr-4"
                      @click="PO()"
                      :disabled="!formData.Lists.length"
                      v-if="prData.isParent != 1"
                    >
                      Purchase Order
                    </v-btn>
                    <v-btn
                      color="green darken-2"
                      outlined
                      class="mb-2 ml-4 mt-2 mr-4"
                      @click="selectItems()"
                      :disabled="!formData.Lists.length"
                      v-if="prData.isParent == 1"
                    >
                      Select Items
                    </v-btn>

                    <!----dialaog to edit grand total------------->
                    <!-- <v-dialog v-model="dialog" persistent max-width="290">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="amber darken-2"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click="editGrandTotal(prData.GrandTotal)"
                          :disabled="!EnableGrandtotal"
                        >
                          Edit Grand Total
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="text-h5">
                          Edit Grand Total
                        </v-card-title>
                        <v-card-text>
                          <v-text-field
                            dense
                            v-model="prData.GrandTotal"

                          ></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red darken-1"
                            text
                            @click="dialog = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="green darken-1"
                            text
                            @click="dialog = false"
                          >
                            Continue
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog> -->
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.ppmpItemID">
                      <td>
                        <v-checkbox
                          v-model="formData.Lists"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          hide-details
                          color="header"
                        />
                      </td>
                      <td>
                        <!-- <v-edit-dialog :return-value.sync="item.ItemName" large> -->
                          {{ item.ItemName }}
                          <!-- <template v-slot:input>
                            <v-textarea v-model="item.ItemName"> </v-textarea>
                          </template>
                        </v-edit-dialog> -->
                      </td>
                      <td style="max-width: 200px">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.itemDescription"
                        >
                          {{ item.itemDescription }}
                          <template v-slot:input>
                            <v-textarea v-model="item.itemDescription">
                            </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>
                          {{ item.UnitMeasureDescription }}
                      </td>
                      <td>
                        {{getUCDescription(item.UnitConversionID)}}
                      </td>
                      <td>
                        <v-edit-dialog large :return-value.sync="item.UnitCost">
                          <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                          <template v-slot:input>
                            <v-text-field v-model="item.UnitCost" type="number">
                            </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td align="center">
                          {{formatQty(item.Qty || 0) }}
                      </td>
                      <td align="center">
                          {{formatQty(item.Qty_PO || 0) }}
                      </td>
                      <td align="center">
                          {{ formatQty(item.Qty_waived || 0) }}
                      </td>
                      <td align="center">
                          {{ formatQty(item.Qty_allowable || 0) }}
                      </td>
                      <td align="center">
                      <v-edit-dialog
                          large
                          :return-value.sync="item.editableQty"
                        >
                          <span
                            :class="
                              parseFloat(item.editableQty) + parseFloat(item.IARQTY) > parseFloat(item.Qty_allowable) || parseFloat(item.editableQty) <= 0
                                ? 'red--text'
                                : 'green--text'
                            "
                          >
                            {{ item.editableQty }}
                          </span>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.editableQty"
                              type="number"
                              :rules="[parseFloat(item.editableQty) + parseFloat(item.IARQTY) <= parseFloat(item.Qty_allowable), parseFloat(item.editableQty) > 0]"
                            >
                            </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td align="end">
                        <span
                            :class="
                              parseFloat((parseFloat(item.editableQty) *
                                parseFloat(item.UnitCost)).toFixed(2)) >
                              parseFloat(parseFloat(item.totalAmount).toFixed(2))
                                ? 'red--text'
                                : 'green--text'
                            "
                          >
                        <span class="text-no-wrap">
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.editableQty)
                          )
                        }}
                        </span>
                        </span>
                      </td>
                      <td align="end">
                        <span class="text-no-wrap">
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.totalAmount)
                          )
                        }}
                        </span>
                      </td>
                      <td align="center">
                        <v-badge
                          bordered
                          color="error"
                          icon="mdi-check-bold"
                          overlap
                          :value="item.conversion_data && item.conversion_data.UnitMeasureID != null"
                        >
                        <v-btn color="red darken-2" small class="pa-0 white--text" :disabled="item.unitconversion.length <= 1" @click="convertItem(item)"><v-icon>mdi-cached</v-icon></v-btn>
                        </v-badge>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-textarea
                color="green"
                dense
                v-model="notes"
                label="Add Notes on this PO:"
                counter
                maxlength="2000"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="PurposeDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="">Purchase Order</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    class="mt-2"
                    color="green"
                    v-model="formData.SupplierID"
                    item-text="CompanyName"
                    item-value="SupplierID"
                    :items="supplier"
                    dense
                    :rules="[formRules.required]"
                    label="Supplier"
                  >
                    <template slot="selection" slot-scope="data">
                      <span class="mr-2"> {{ data.item.CompanyName }}</span>
                      <span>({{ data.item.CompanyAddress }})</span>
                    </template>
                    <template slot="item" slot-scope="data">
                      <span class="mr-2"> {{ data.item.CompanyName }}</span>
                      <span>({{ data.item.CompanyAddress }})</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    class="mt-2"
                    color="green"
                    v-model="formData.mopID"
                    item-text="mopDescription"
                    item-value="mopID"
                    :items="mop"
                    dense
                    :rules="[formRules.required]"
                    label="Mode of Procurement"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    class="mt-2"
                    color="green"
                    v-model="formData.podID"
                    item-text="podDescription"
                    item-value="podID"
                    :items="pod"
                    dense
                    :rules="[formRules.required]"
                    label="Place of Delivery"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    class="mt-2"
                    color="green"
                    v-model="formData.dodID"
                    item-text="dodDescription"
                    item-value="dodID"
                    :items="dod"
                    dense
                    :rules="[formRules.required]"
                    label="Date of Delivery"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    class="mt-2"
                    color="green"
                    v-model="formData.dtID"
                    item-text="dtDescription"
                    item-value="dtID"
                    :items="deliveryterm"
                    dense
                    :rules="[formRules.required]"
                    label="Delivery Term"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    class="mt-2"
                    color="green"
                    v-model="formData.ptID"
                    item-text="ptDescription"
                    item-value="ptID"
                    :items="paymentterm"
                    dense
                    :rules="[formRules.required]"
                    label="Payment Term"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" outlined @click="PurposeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-2" dark @click="submitPO()" :loading="creatingPO">
            <v-icon>mdi-check</v-icon>
            Create PO
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="SelectionDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="">Selected Items</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="SelectionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
              <v-row justify="center">
                <v-data-table
                v-model="formData.Lists"
                :headers="headers2"
                :items="formData.Lists"
                item-key="ppmpItemID"
                class="elevation-1 mt-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td>
                          {{ item.ItemName }}
                      </td>
                      <td style="max-width: 200px">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.itemDescription"
                        >
                          {{ item.itemDescription }}
                          <template v-slot:input>
                            <v-textarea v-model="item.itemDescription">
                            </v-textarea>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td align="right">
                        <v-edit-dialog large :return-value.sync="item.UnitCost">
                          ₱ {{ formatPrice(item.UnitCost) }}
                          <template v-slot:input>
                            <v-text-field v-model="item.UnitCost" type="number">
                            </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td align="center">
                        <v-edit-dialog
                          large
                          :return-value.sync="item.editableQty"
                        >
                          <span
                            :class="
                              parseFloat(item.editableQty) + parseFloat(item.IARQTY) > parseFloat(item.Qty_allowable) || parseFloat(item.editableQty) <= 0
                                ? 'red--text'
                                : 'green--text'
                            "
                          >
                            {{ item.editableQty }}
                          </span>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.editableQty"
                              type="number"
                              :rules="[parseFloat(item.editableQty) + parseFloat(item.IARQTY) <= parseFloat(item.Qty_allowable), parseFloat(item.editableQty) > 0]"
                            >
                            </v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                      <td align="right">
                        <span
                            :class="
                              parseFloat((parseFloat(item.editableQty) *
                                parseFloat(item.UnitCost)).toFixed(2)) >
                              parseFloat(parseFloat(item.totalAmount).toFixed(2))
                                ? 'red--text'
                                : ''
                            "
                          >
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.editableQty)
                          )
                        }}
                        </span>
                      </td>
                      <td align="right">
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.totalAmount)
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
              </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="SelectionDialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-2" outlined @click="PO()">
            Purchase Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="convertDialog" persistent max-width="500px">
          <v-card>
            <v-card-title class="headline">Unit Conversion</v-card-title>
            <div class="mx-6 mt-3">
              <v-form @submit.prevent ref="UCFormRef">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete hide-details v-model="convert_data.UnitConversionID" @change="convert()" :items="convert_data.unitconversion" class="pa-0 mr-1" item-text="description" item-value="id" color="header" outlined dense></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-card
                    class="pa-3"
                    color="grey lighten-3"
                    flat
                  >
                  <v-card-text class="pa-0 ma-0">
                  <v-row no-gutters v-if="convert_data">
                    <v-col cols="12" sm="3">
                      <strong>Item: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{convert_data.ItemName}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="convert_data">
                    <v-col cols="12" sm="3">
                      <strong>Amount: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{formatPrice(convert_data.Qty * convert_data.UnitCost)}}
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="3">
                      <strong>Conversion: </strong>
                    </v-col>
                    <v-col cols="12" sm="9">
                      {{getUCDescription(convert_data.UnitConversionID)}}
                    </v-col>
                  </v-row>
                  </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="convert_item.Qty"
                    :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                    @keyup="changeUnit()"
                    type="number"
                    label="Quantity"
                    color="header"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="convert_item.UnitCost"
                    :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed]"
                    @keyup="changeUnit()"
                    type="number"
                    label="UnitCost"
                    color="header"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="convert_item.Amount"
                    :rules="[formRules.noNegativeNumberAllowed, formRules.numberRequiredZeroAllowed, checkAmount(parseFloat((convert_data.Qty * convert_data.UnitCost)), parseFloat(convert_item.Amount))]"
                    type="number"
                    label="Amount"
                    color="header"
                    readonly
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              </v-form>
            </div>
            <v-card-actions class="mt-5 pb-4">
              <v-spacer></v-spacer>
              <!-- <v-btn color="grey darken-2" outlined @click="closeConvertDialog()">Close</v-btn> -->
              <v-btn color="red darken-2 white--text" @click="confirmConvert()" :loading="isLoading"><v-icon class="mr-1">mdi-check-circle</v-icon>Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    prData: {},
  },
  data: () => ({
    notes: "",
    tempGrandTotal: null,
    EnableGrandtotal: false,
    dialog: false,
    PurposeDialog: false,
    dialogModel: false,
    formData: {
      Lists: [],
      dtID: null,
      dodID: null,
      mopID: null,
      ptID: null,
      SupplierID: null,
      podID: null,
    },
    itemList: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      {
        text: "Unit Conversion",
        value: "UCDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "Qty (Overall)", value: "Qty", sortable: false, align: 'center' },
      { text: "Qty (PO)", value: "Qty_PO", sortable: false, align: 'center' },
      { text: "Qty (Waived)", value: "Qty_waived", sortable: false, align: 'center' },
      { text: "Qty (Allowable)", value: "Qty_allowable", sortable: false, align: 'center' },
      { text: "Qty (Editable)", value: "editableQty", sortable: false, align: 'center' },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' },
      { text: "Amount (Allowable)", value: "totalAmount", sortable: false,  align: 'end' },
      { text: "Action", value: "Action", sortable: false, align: "center" },
    ],
    headers2: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "itemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false },
      { text: "Qty", value: "editableQty", sortable: false, align: 'center' },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' },
      { text: "Amount (Allowable)", value: "totalAmount", sortable: false,  align: 'end' },
    ],
    deliveryterm: [],
    supplier: [],
    dod: [],
    mop: [],
    paymentterm: [],
    pod: [],
    specificFundSource: [],
    SpecificFundSourceID: null,
    SelectionDialog: false,
    uclist: [],
    uclist_filtered: [],
    convert_data: {},
    convert_item: {},
    convertDialog: false,
    isLoading: false,
    creatingPO: false,
  }),
  watch: {
    prData: {
      handler(data) {
        if (data.PRID) {
          if(!this.dialog){
          this.getSpecificFundSource();
          this.getPOUtil();
          this.getUnitConversion();
          }
          this.dialogModel = true;
        }
      },
      deep: true,
    },
    itemList: {
      handler(data) {
        this.EnableGrandtotal = false;
        data.forEach(item => {
          if (item.UnitCost == 0) {
          this.EnableGrandtotal = true;
          return;
        }
        });
      },
      deep: true,
    },
    SpecificFundSourceID: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  methods: {
    selectItems(){
      this.SelectionDialog = true;
    },
    getPOUtil() {
      let data = new FormData();
      this.axiosCall("/get/all/POUtil", "POST", data).then((res) => {
        this.deliveryterm = res.data.data.deliveryterm;
        this.supplier = res.data.data.supplier;
        this.dod = res.data.data.dod;
        this.mop = res.data.data.mop;
        this.paymentterm = res.data.data.paymentterm;
        this.pod = res.data.data.pod;
      });
    },
    PO() {
      this.PurposeDialog = true;
    },
    editGrandTotal(amount) {
      if(this.tempGrandTotal == null){
        this.tempGrandTotal = amount;
      }
    },
    submitPO() {
      if (this.$refs.Formref.validate()) {
        let error = false;
        for (let list of this.formData.Lists) {
          if (
            parseFloat(list.editableQty) + parseFloat(list.IARQTY) >
              parseFloat(list.Qty_allowable) ||
            parseFloat(list.editableQty) == 0 ||
            parseFloat((parseFloat((list.editableQty) * parseFloat(list.UnitCost))).toFixed(2)) > parseFloat(parseFloat(list.totalAmount).toFixed(2))
          ) {
            error = true;
          }
        }
        if (error) {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.header = "System Message";
          this.fadeAwayMessage.message =
            "Failed to continue. Please check the details.";
          this.PurposeDialog = false;
        } else {
        this.creatingPO = true;
        let data = new FormData();
        data.append("PRDATA", JSON.stringify(this.prData));
        data.append("List", JSON.stringify(this.formData.Lists));

        data.append("SupplierID", JSON.stringify(this.formData.SupplierID));
        data.append("ptID", JSON.stringify(this.formData.ptID));
        data.append("mopID", JSON.stringify(this.formData.mopID));
        data.append("dodID", JSON.stringify(this.formData.dodID));
        data.append("dtID", JSON.stringify(this.formData.dtID));
        data.append("podID", JSON.stringify(this.formData.podID));
        data.append("ismanual", this.prData.GrandTotal == this.tempGrandTotal || this.tempGrandTotal == null ? 0 : 1);
        data.append("note", this.notes);
        this.axiosCall("/po/add", "POST", data).then((res) => {
          this.formData.dtID = null;
          this.formData.dodID = null;
          this.formData.mopID = null;
          this.formData.ptID = null;
          this.formData.SupplierID = null;
          this.formData.podID = null;
          this.notes = '';
          this.getItems();
          this.PurposeDialog = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Success!";
          this.fadeAwayMessage.header = "System Message";
          this.creatingPO = false;
          window.open(this.apiUrl + "/po/report/" + res.data.data);
        });
        }
      }
    },
    clickCloseMainDialogPR() {
      this.formData.Lists = [];

      this.formData.dtID = null;
      this.formData.dodID = null;
      this.formData.mopID = null;
      this.formData.ptID = null;
      this.formData.SupplierID = null;
      this.formData.podID = null;
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closePOModal", false);
    },
    getSpecificFundSource(){
      this.formData.Lists = [];
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      this.axiosCall("/pr/getItems/specificFundSource", "POST", data).then((res) => {
        this.specificFundSource = res.data.data.result;
        this.getItems();
      });
    },
    getItems() {
      this.formData.Lists = [];
      this.itemList = [];
      let data = new FormData();
      data.append("PRID", this.prData.PRID);
      data.append("SpecificFundSourceID", this.SpecificFundSourceID);
      this.axiosCall("/pr/getItems/po", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        this.itemList.forEach(item => {
          item.UnitConversionID = 0
          item.unitconversion = this.getFilteredUClist(item.UnitMeasureID)
          item.conversion_data = {
            UnitMeasureID: null,
            Qty: 0,
            UnitCost: 0,
            Amount: 0,
          }
        });
      });
    },
    closeConvertDialog(){
      this.convertDialog = false;
    },
    getUnitConversion() {
      this.axiosCall("/getAllUnitConversion", "GET").then((res) => {
        this.uclist = res.data.data;
      });
    },
    getFilteredUClist(id){
      let result = [{
        id: 0,
        description: "None"
      }];
      this.uclist.forEach(item => {
        if(id == item.UnitMeasureID_from){
          result.push(item)
        }
      });
      return result;
    },
     convertItem(data){
      this.convert_data = data;
      this.convert_item = data.conversion_data;
      this.convertDialog = true;
     },
    getUCDescription(id){
      let result = "None";
      this.uclist.forEach(item => {
        if(id == item.id){
          result = item.description
        }
      });
      return result;
    },
    convert(){
      let result = null;
      this.uclist.forEach(item => {
        if(this.convert_data.UnitConversionID == item.id){
          result = item
        }
      });
      if(result){
        let factor = parseFloat(result.factor);
        let multiply = parseFloat(result.multiply);
        let UnitMeasureID = result.UnitMeasureID_to;
        this.convert_item.Qty = parseFloat(((parseFloat(this.convert_data.Qty) / factor) * multiply).toFixed(2))
        this.convert_item.UnitCost = parseFloat(((parseFloat(this.convert_data.UnitCost) * factor) * multiply).toFixed(2))
        this.convert_item.Amount = this.convert_item.Qty * this.convert_item.UnitCost;
        this.convert_item.UnitMeasureID = UnitMeasureID;
      }
      else{
        this.convert_item.Qty = 0;
        this.convert_item.UnitCost = 0;
        this.convert_item.Amount = 0;
        this.convert_item.UnitMeasureID = null;
      }
    },
    confirmConvert(){
      if(this.$refs.UCFormRef.validate()){
      this.convert_data.conversion_data = this.convert_item;
      let temp = JSON.stringify(this.formData.Lists);
      this.formData.Lists = this.itemList;
      this.formData.Lists = JSON.parse(temp);
      this.convertDialog = false;
      }
    },
    changeUnit(){
      this.convert_item.Amount = parseFloat(this.convert_item.Qty) * parseFloat(this.convert_item.UnitCost);
    },
    checkAmount(value1, value2){
      return value1 >= value2 || "Amount Exceeded!"
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>